import { Trans } from "@lingui/react/macro";
import { msg } from "@lingui/core/macro";
import { FilledButton } from "@components/Button/Button";
import { Iframe } from "@components/Iframe/Iframe";
import { useNavigate } from "react-router";
import styles from "./CompodiumVideo.module.scss";
import { useEffect, useState } from "react";
import { Loading } from "@components/Loading/Loading";
import { useLingui } from "@lingui/react";

export const CompodiumVideo = ({ meetingUrl }: { meetingUrl: string }) => {
  const navigate = useNavigate();
  const { _ } = useLingui();
  const [permissionsSurfaced, setPermissionsSurfaced] = useState(false);
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then(() =>
        // DEBUGGING: Log available devices to clarify what Compodium probably sees
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          devices.forEach((device, index) =>
            console.log(`DEVICE ${index + 1}:`, device.toJSON()),
          );
        }),
      )
      .finally(() => setPermissionsSurfaced(true));
  }, []);
  return !permissionsSurfaced ? (
    <Loading
      message={_(msg`Ger videotjänsten tillgång till mikrofon och kamera`)}
    />
  ) : (
    <>
      <Iframe
        src={meetingUrl}
        allow="camera;microphone;"
        loadingMessage={_(msg`Startar video`)}
        title={_(msg`Video`)}
      />
      <div className={styles.buttonWrapper}>
        <FilledButton
          onClick={() => {
            navigate("/schedule");
            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage("video:finished");
            }
          }}
        >
          <Trans>Gå till schemat</Trans>
        </FilledButton>
      </div>
    </>
  );
};
