import clsx from "clsx";
import styles from "./travellingToTag.module.scss";
import ArrowRightFilledIcon from "@components/icons/ArrowRightFilledIcon";
import { Tag } from "@components/Tag/Tag";
import { useLingui } from "@lingui/react";
import { visitStatusSchema } from "@models/activities";
import { visitStatusDictionary, type IVisitStatus } from "@models/visits";

const statusToColorMap: Record<
  Extract<IVisitStatus, "travellingTo">,
  "blue"
> = {
  travellingTo: "blue",
};
export const TravellingToTag = () => {
  const status = visitStatusSchema.Values.travellingTo;
  const { _ } = useLingui();
  const label = _(visitStatusDictionary[status]);

  const Icon = () => (
    <div className={styles.iconWrapper}>
      <div className={clsx(styles.icon, styles[`icon-${status}`])}>
        <ArrowRightFilledIcon />
      </div>
    </div>
  );

  return (
    <Tag
      icon={<Icon />}
      color={statusToColorMap[status]}
      label={label}
      size="small"
      variant="icon-and-text"
    />
  );
};
