import { t } from "@lingui/core/macro";
import { isToday, isTomorrow } from "date-fns";
import { Heading } from "@components/Heading/Heading";
import styles from "./scheduleDayHeading.module.scss";
import { i18n } from "@lingui/core";

export const ScheduleDayHeading = ({ date }: { date: Date }) => {
  const dayAndMonth = i18n.date(date, { day: "numeric", month: "long" });

  const weekDay = i18n.date(date, { weekday: "long" });
  const weekdayCapitalized = weekDay.charAt(0).toUpperCase() + weekDay.slice(1);

  const day = isToday(date)
    ? t`Idag`
    : isTomorrow(date)
      ? t`Imorgon`
      : weekdayCapitalized;

  return (
    <header>
      <Heading level="h2" size="h1" className={styles.heading}>
        {day}
        <span className={styles.dayAndMonth}>{dayAndMonth}</span>
      </Heading>
    </header>
  );
};
