import { z } from "zod";
import { notificationApi } from "./apiClient";
import { queryOptions } from "@tanstack/react-query";

const callStatuses = ["NoOngoingCall", "OngoingCall"] as const;
export const callStatusSchema = z.enum(callStatuses);
export const callStatusWrapperSchema = z.object({
  callStatus: callStatusSchema,
});

const fetchCallIsOngoing = async () => {
  const url = `videocall`;
  const response = await notificationApi.get(url);
  return (
    callStatusWrapperSchema.parse(response.data).callStatus ===
    callStatusSchema.Values.OngoingCall
  );
};

export const callIsOngoingQueryOptions = queryOptions({
  queryKey: ["callStatus"],
  queryFn: fetchCallIsOngoing,
});
