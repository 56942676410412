import { msg } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { getStatus, useMySchedule } from "@/api/schedule";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { GroupCard } from "./groupCard";
import { ActivityCard } from "./activityCard";
import styles from "./schedule.module.scss";
import { Text } from "@components/Text/Text";
import { ScheduleDayHeading } from "@/routes/schedule/scheduleDayHeading";
import { FinishedItem } from "./finishedItem";
import { Heading } from "@components/Heading/Heading";
import CheckFilledIcon from "@components/icons/CheckFilledIcon";
import * as Sentry from "@sentry/react";
import { categorySchema, isGroup } from "@models/activities";
import { useLingui } from "@lingui/react";
import { i18n } from "@lingui/core";
import { activityCategoryDictionary } from "@/api/activities";

const FinishedItemsHeader = () => {
  return (
    <div className={styles.finishedItemsHeader}>
      <span className={styles.checkMark}>
        <CheckFilledIcon />
      </span>
      <Heading level="h2" size="h3" weight="bold">
        <Trans>Utfört under dagen</Trans>
      </Heading>
    </div>
  );
};

export const Schedule = () => {
  const { data: schedule, isPending, isError, error } = useMySchedule();
  const { _ } = useLingui();

  if (isPending) {
    return <Loading message={_(msg`Hämtar dina aktiviteter`)} />;
  }

  // Log errors when fetching schedule, even if we prefer to show old data.
  if (isError) {
    Sentry.captureException(error);
  }

  // Show (old) data rather than error, even if there is an error
  if (!schedule && isError) {
    return (
      <ErrorMessage
        message={_(msg`Något gick fel när vi hämtade dina aktiviteter`)}
      />
    );
  }

  const daysWithItems = schedule.filter((scheduleItemsPerDay) =>
    scheduleItemsPerDay.some((activityOccurrence) =>
      Boolean(activityOccurrence),
    ),
  );

  if (daysWithItems.length === 0) {
    return (
      <Text element="span">
        <Trans>
          Det finns inga aktiviteter inplanerade för dig. Om du har frågor kan
          du skriva till oss i chatten
        </Trans>
      </Text>
    );
  }

  return (
    <ol className={styles.schedule}>
      {schedule.map((scheduleItemsPerDay) => {
        const oneScheduledItemFromThatDay = scheduleItemsPerDay[0];
        if (!oneScheduledItemFromThatDay) {
          return null;
        }
        const date = oneScheduledItemFromThatDay.start;
        const dateFormatted = i18n.date(date, {
          weekday: "long",
          day: "numeric",
          month: "long",
        });
        const finishedItems = scheduleItemsPerDay.filter(
          (activityOccurrence) => getStatus(activityOccurrence) === "finished",
        );
        const unfinishedItems = scheduleItemsPerDay.filter(
          (activityOccurrence) => getStatus(activityOccurrence) !== "finished",
        );

        return (
          <li key={dateFormatted} className={styles.daySchedule}>
            <ScheduleDayHeading date={date} />
            {finishedItems.length > 0 ? (
              <ol className={styles.finishedItems} aria-label={_(msg`Utfört`)}>
                <FinishedItemsHeader />
                {finishedItems.map((finishedItem) => {
                  if (isGroup(finishedItem)) {
                    return (
                      <li key={finishedItem.id}>
                        <FinishedItem
                          title={_(
                            activityCategoryDictionary[
                              categorySchema.Values.HomeVisit
                            ],
                          )}
                        />
                      </li>
                    );
                  }
                  return (
                    <li key={`${finishedItem.activityId}${finishedItem.id}`}>
                      <FinishedItem
                        title={finishedItem.title}
                        timeStamp={finishedItem.finishedAt}
                      />
                    </li>
                  );
                })}
              </ol>
            ) : null}
            <ol className={styles.unfinishedItems}>
              {unfinishedItems.map((activityOccurrenceOrGroup) => {
                if (isGroup(activityOccurrenceOrGroup)) {
                  const group = activityOccurrenceOrGroup;
                  return (
                    <li key={group.id}>
                      <GroupCard group={group} />
                    </li>
                  );
                }
                const activityOccurrence = activityOccurrenceOrGroup;
                return (
                  <li key={activityOccurrence.id}>
                    <ActivityCard activityOccurrence={activityOccurrence} />
                  </li>
                );
              })}
            </ol>
          </li>
        );
      })}
    </ol>
  );
};
