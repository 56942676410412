import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { Heading } from "@components/Heading/Heading";
import { Text } from "@components/Text/Text";
import styles from "./guide.module.scss";
import { GoBackButton } from "@/routes/healthCare/goBackButton";
import { useNavigate } from "react-router";
import { usePatient } from "@/api/patient";

export const Guide = ({ goBackEnabled = true }) => {
  const navigate = useNavigate();
  const { data: patient } = usePatient();
  const patientFirstName = patient ? patient.name.split(" ")[0] : "";

  return (
    <div className={styles.container}>
      {goBackEnabled ? (
        <div className={styles.goBackButton}>
          <GoBackButton onClick={() => navigate("..")} size="large" />
        </div>
      ) : null}
      <img src="/hospitalAtHome.avif" alt="" className={styles.image} />
      <div className={styles.infoText}>
        <Heading level="h1" className={styles.heading}>
          {patient ? t`Hej ${patientFirstName}!` : t`Hej!`}
        </Heading>
        <Text element="p" size="small">
          <Trans>
            Vi är glada att kunna erbjuda dig möjligheten att fortsätta din
            sjukhusvård i ditt eget hem. Här får du samma säkerhet, kvalitet och
            trygghet som på sjukhuset, men med bekvämligheten av att vara hemma.
          </Trans>
        </Text>
        <Text element="p" size="small">
          <Trans>
            När du vårdas hemma minskar risken för sjukhusrelaterade
            komplikationer, som till exempel att smittas av en infektion. Vård i
            hemmet brukar också innebära att patienter återhämtar sig snabbare
            med och bättre sömn, rörelse och mat.
          </Trans>
        </Text>
        <Text element="p" size="small">
          <Trans>
            Du kan kontakta oss dygnet runt via chatt eller video via din
            surfplatta. Du kommer ha daglig videorond med läkare och
            sjuksköterska. Varje morgon och kväll tar du dina mätvärden med
            medföljande utrustning.
          </Trans>
        </Text>
        <Text element="p" size="small">
          <Trans>
            Kvalificerad sjukvårdspersonal kommer hem till dig utifrån dina
            behov. Du kan få besök för exempelvis medicinering, undersökningar
            och provtagningar. Både planerade och akuta hembesök kan göras
            dygnet runt.
          </Trans>
        </Text>
        <Text element="p" size="small">
          <Trans>
            Vi ser fram emot att stödja dig i din återhämtning och finns här för
            att hjälpa dig på bästa sätt.
          </Trans>
        </Text>
      </div>
    </div>
  );
};
