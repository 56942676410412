import { UnstyledLink } from "@components/Link/Link";
import styles from "./Banner.module.scss";

export const Banner = ({
  children,
  isVisible,
  linkTo,
}: {
  children: React.ReactNode;
  isVisible: boolean;
  linkTo?: string;
}) => {
  return isVisible ? (
    <div className={styles.overlay}>
      {linkTo !== undefined ? (
        <UnstyledLink to={linkTo}>
          <div className={styles.content}>{children}</div>
        </UnstyledLink>
      ) : (
        <div className={styles.content}>{children}</div>
      )}
    </div>
  ) : null;
};
