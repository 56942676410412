import { Trans } from "@lingui/react/macro";
import { Heading } from "@components/Heading/Heading";
import { Text } from "@components/Text/Text";
import styles from "./patientNotes.module.scss";
import { GoBackButton } from "@/routes/healthCare/goBackButton";
import { useNavigate } from "react-router";
import { useAcceptedPatientNote } from "@/api/patientNote";
import { i18n } from "@lingui/core";

export const PatientNotes = () => {
  const navigate = useNavigate();
  const {
    data: acceptedPatientNote,
    isPending,
    isError,
  } = useAcceptedPatientNote();

  // Accepted patient note is always available at this point so no need to render loading or error state
  if (isPending || isError || !acceptedPatientNote) {
    return null;
  }

  const splitConvertedTextInParagraphs = acceptedPatientNote.convertedText
    .split("\n")
    .filter((paragraph) => paragraph.trim() !== "");

  const acceptedBy = acceptedPatientNote.acceptedBy ?? "";
  const acceptedDate = acceptedPatientNote.conversionTimeStamp
    ? i18n.date(acceptedPatientNote.conversionTimeStamp)
    : null;

  return (
    <>
      <GoBackButton onClick={() => navigate("..")} size="large" />
      <div className={styles.infoText}>
        <Heading level="h1" className={styles.heading}>
          <Trans>Detta har hänt</Trans>
        </Heading>
        <Text element="p" size="small" color="faded">
          <Trans>
            Texten baseras på läkarens anteckning och har gjorts mer läsbar med
            AI.
          </Trans>
        </Text>
        {splitConvertedTextInParagraphs.map((paragraph, index) => (
          <Text element="p" key={index} size="small">
            {paragraph}
          </Text>
        ))}
        <div className={styles.sentInfo}>
          <Text element="span" size="small" color="faded">
            <Trans>Godkänd av {acceptedBy}</Trans>
          </Text>
          {acceptedDate ? (
            <Text element="span" size="small" color="faded">
              {acceptedDate}
            </Text>
          ) : null}
        </div>
      </div>
    </>
  );
};
