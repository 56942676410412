import { msg } from "@lingui/core/macro";
import { useVideoInfo } from "@/api/Video";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import { JitsiVideo } from "@/components/Video/JitsiVideo";
import styles from "./VideoMeeting.module.scss";
import { CompodiumVideo } from "@/components/Video/CompodiumVideo";
import { useSearchParams } from "react-router";
import type { IVideoService } from "@models/video";
import * as Sentry from "@sentry/react";
import { Suspense, lazy } from "react";
import { useLingui } from "@lingui/react";

// Heavy dependency, lazy load it
const AzureVideo = lazy(() => import("@/components/Video/AzureVideo"));

export const VideoMeeting = () => {
  const [searchParams] = useSearchParams();
  const requestedVideoService = searchParams.get("video-service");
  const videoService: IVideoService =
    requestedVideoService === "compodium"
      ? "Compodium"
      : requestedVideoService === "acs"
        ? "ACS"
        : "Jitsi";
  const {
    data: videoInfo,
    isError,
    isPending,
    error,
  } = useVideoInfo(videoService);
  const { _ } = useLingui();

  if (isPending) {
    return <Loading message={_(msg`Startar videosamtal`)} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage message={_(msg`Det blev ett fel med videosamtalet.`)} />
    );
  }

  if (!videoInfo) {
    return (
      <ErrorMessage message={_(msg`Det blev ett fel med videotjänsten.`)} />
    );
  }

  return (
    <div className={styles.container}>
      {videoInfo.videoService === "Jitsi" ? (
        <JitsiVideo
          roomName={videoInfo.room}
          // Guide: https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-configuration/
          // All config options: https://github.com/jitsi/jitsi-meet/blob/master/config.js
          // All config options available to be overwritten from client: https://github.com/jitsi/jitsi-meet/blob/master/react/features/base/config/configWhitelist.ts
          configOverwrite={{
            startWithAudioMuted: false,
            startWithVideoMuted: false,
            toolbarButtons: ["hangup"],
            customParticipantMenuButtons: [], // Could be used to add some custom functionality
            welcomePage: {
              disabled: true,
            },
            prejoinConfig: {
              enabled: false,
            },
            disableDeepLinking: true, // Will not ask user to open in app (defaults to browser)
            subject: _(msg`Videosamtal med Medoma`),
            notifications: [{ "notify.grantedTo": false }], // Avoid mentioning moderator rights are given
            hideDisplayName: true, // Don't show the name of who you are talking to
            conferenceInfo: {
              autoHide: [], // Hides all things from the top menu (quality, time, name of meeting)
            },
            filmstrip: {
              disableResizable: true, // Can't disable whole filmstrip it seems, but at least we can simplify by not making it resizable
            },
            disableModeratorIndicator: true, // Avoid showing "M"
            disableLocalVideoFlip: true, // Avoid strange actions
            disableSelfViewSettings: true, // Avoid strange actions
            enableNoisyMicDetection: false, // Avoid unwanted notifications (care personnel can tell the patient instead)
            disableSelfView: false, // Self view disappeared for a while without this config, keep just in case
          }}
          jwt={videoInfo.token}
          videoServer={videoInfo.domain}
        />
      ) : videoInfo.videoService === "Compodium" ? (
        <CompodiumVideo meetingUrl={videoInfo.meetingUrl} />
      ) : (
        <Suspense fallback={<Loading />}>
          <AzureVideo
            userId={videoInfo.userId}
            token={videoInfo.token}
            displayName={_(msg`Patient`)}
            groupId={videoInfo.groupId}
          />
        </Suspense>
      )}
    </div>
  );
};
