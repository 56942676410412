import { Trans } from "@lingui/react/macro";
import type { IPlainButton } from "@components/Button/Button";
import { PlainButton } from "@components/Button/Button";
import ArrowLeftIcon from "@components/icons/ArrowLeftIcon";

type IGoBackButton = Omit<IPlainButton, "children">;

export const GoBackButton = (props: IGoBackButton) => {
  return (
    <PlainButton contentType="text-and-icon" {...props}>
      <ArrowLeftIcon /> <Trans>Tillbaka</Trans>
    </PlainButton>
  );
};
