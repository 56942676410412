import { msg } from "@lingui/core/macro";
import { useLocation, useNavigate } from "react-router";
import { Iframe } from "@components/Iframe/Iframe";
import { PATIENT_HTMX_API_URL } from "@/utils/envUtils";
import { useEffect, useState } from "react";
import { useLingui } from "@lingui/react";

export const Measurements = () => {
  const { search } = useLocation();
  const measurementsChooserQueryString = search === "" ? "" : `/add${search}`;
  const navigate = useNavigate();
  const { _ } = useLingui();

  const postMessageHandler = {
    messageType: "cancelMeasurementsForm",
    onMessageReceived: () => {
      navigate("..");
    },
  };

  const [iframeKey, setIframeKey] = useState(0);
  useEffect(() => {
    const reloadIframe = () => {
      setIframeKey((prevKey) => prevKey + 1);
    };

    window.addEventListener("visibilitychange", reloadIframe);
    return () => {
      window.removeEventListener("visibilitychange", reloadIframe);
    };
  }, []);

  return (
    <div style={{ position: "absolute", inset: 0 }}>
      <Iframe
        title={_(msg`Mätvärden`)}
        src={`${PATIENT_HTMX_API_URL}/htmx/measurements${measurementsChooserQueryString}`}
        loadingMessage={_(msg`Hämtar mätvärden`)}
        postMessageHandler={postMessageHandler}
        key={iframeKey}
      />
    </div>
  );
};
