import { t } from "@lingui/core/macro";
import styles from "./finishedItem.module.scss";
import { Text } from "@components/Text/Text";
import { i18n } from "@lingui/core";

type IFinishedItem = {
  title: string;
  timeStamp?: Date | null | undefined;
};
export const FinishedItem = ({ title, timeStamp }: IFinishedItem) => {
  const finished = t`klart`;
  return (
    <Text element="div" size="small" className={styles.finishedItem}>
      {title}
      {timeStamp
        ? `, ${finished} ${i18n.date(timeStamp, { timeStyle: "short" })}`
        : ""}
    </Text>
  );
};
