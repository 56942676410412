import { Trans } from "@lingui/react/macro";
import { msg } from "@lingui/core/macro";
import { Heading } from "@components/Heading/Heading";
import styles from "./healthCare.module.scss";
import { useAcceptedPatientNote } from "@/api/patientNote";
import { Guide } from "./guide";
import { Text } from "@components/Text/Text";
import * as Sentry from "@sentry/react";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { NavCard } from "@/routes/healthCare/navCard";
import { useLingui } from "@lingui/react";

export const HealthCare = () => {
  const {
    data: acceptedPatientNote,
    isPending,
    isError,
    error,
  } = useAcceptedPatientNote();
  const { _ } = useLingui();
  if (isPending) {
    return <Loading message={_(msg`Hämtar information om din vård`)} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={_(msg`Något gick fel när vi hämtade information om din vård`)}
      />
    );
  }

  if (!acceptedPatientNote) {
    return <Guide goBackEnabled={false} />;
  }

  return (
    <>
      <Heading level="h1" className={styles.heading}>
        <Trans>Din vård</Trans>
      </Heading>
      <Text element="p">
        <Trans>
          Här har vi samlat information om din vård. Att du förstår ditt
          medicinska tillstånd kan stärka din förmåga att aktivt bidra till din
          återhämtning.
        </Trans>
      </Text>
      <div className={styles.navigationCards}>
        <NavCard
          link={{
            to: "/healthCare/patientNotes",
            title: _(msg`Detta har hänt`),
            description: _(msg`2 minuters läsning`),
          }}
        />
        <NavCard
          link={{
            to: "/healthCare/patientGuide",
            title: _(msg`Så vårdas du i hemmet`),
            description: _(msg`1 minuts läsning`),
          }}
        />
      </div>
    </>
  );
};
